import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid' //  React components
import MKBox from 'components/MKBox'
import MKTypography from 'components/MKTypography'
// import MKInput from 'components/MKInput'
import MKButton from 'components/MKButton' // Images
import macbook from 'assets/images/macbook.png'

function Newsletter() {
  return (
    <MKBox component="section" pt={6} my={6}>
      <Container>
        <Grid container alignItems="center">
          <Grid item md={6} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <MKTypography variant="h4">Get in touch</MKTypography>
            <MKTypography variant="body2" color="text" mb={3}>
              Your company may not be in the software business, but eventually, a software company
              will be in your business.
            </MKTypography>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <a href="https://forms.gle/FX5HBHD9QqmBG1YK9" target="_blank" rel="noreferrer">
                  <MKButton variant="gradient" color="info" sx={{ height: '100%' }}>
                    Contact Us
                  </MKButton>
                </a>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} sx={{ ml: 'auto' }}>
            <MKBox position="relative">
              <MKBox component="img" src={macbook} alt="macbook" width="100%" />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  )
}
export default Newsletter
