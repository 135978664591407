import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid' //  React components
import MKBox from 'components/MKBox' //  React examples
import DefaultInfoCard from 'examples/Cards/InfoCards/DefaultInfoCard'
import CenteredBlogCard from 'examples/Cards/BlogCards/CenteredBlogCard'
import SystemEngineeringImage from 'assets/images/system-enginnering.jpg'

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="Data solutions"
                    description="Data Warehouseing - Design, implement and maintain your company's data warehouse.
Data Engineering - We build fast, manageable and cost efficient ETL pipelines.
Analytics / BI - Business intelligence at your fingertips. Data driven decision making for your organization."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="payments"
                    title="Machine learning, AI"
                    description="Wonder how you could monetize the opprotunities of AI? Start with our consulting package.
Custom built models for computer vision, NLP and other machine learning tasks or integrate one of our pre-built models for your newest project."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: 'auto', mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={SystemEngineeringImage}
              title="System Engineering"
              description="Modern Web Apps - Let us build your next SPA, SSR, PWA using modern web frameworks."
            // action={{
            //   type: 'internal',
            //   route: 'pages/company/about-us',
            //   color: 'info',
            //   label: 'find out more',
            // }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  )
}
export default Information
