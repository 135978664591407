import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card' //  React components
import MKBox from 'components/MKBox'
import MKTypography from 'components/MKTypography'
import DefaultNavbar from 'examples/Navbars/DefaultNavbar'
import DefaultFooter from 'examples/Footers/DefaultFooter' // About Us page sections
import Information from 'pages/LandingPages/AboutUs/sections/Information'
import Team from 'pages/LandingPages/AboutUs/sections/Team'
import Featuring from 'pages/LandingPages/AboutUs/sections/Featuring'
import Newsletter from 'pages/LandingPages/AboutUs/sections/Newsletter' // Routes
import routes from 'routes'
import footerRoutes from 'footer.routes' // Images
import bgImage from 'assets/images/bg.webp'

function AboutUs() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        action={{
          type: 'external',
          route: 'https://forms.gle/FX5HBHD9QqmBG1YK9',
          label: 'contact',
          color: 'default',
        }}
        transparent
        light
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) => `${linearGradient(
            rgba(gradients.dark.main, 0.2),
            rgba(gradients.dark.state, 0.6),
          )}, url(${bgImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          display: 'grid',
          placeItems: 'center',
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: 'auto', textAlign: 'center' }}
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down('md')]: {
                  fontSize: size['3xl'],
                },
              })}
            >
              We help companies to collect, understand and make better use of data
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
              Analytics, data mining, machine learning and system engineering at your service. Build
              you next SaaS product with us.
            </MKTypography>
            {/* <MKButton color="default" sx={{ color: ({ palette: { dark } }) => dark.main }}>
              create account
            </MKButton> */}
            <MKTypography variant="h6" color="white" mt={8} mb={1}>
              Find us on
            </MKTypography>
            <MKBox display="flex" justifyContent="center" alignItems="center">
              <MKTypography component="a" variant="body1" color="white" href="https://github.com/data-i-consulting">
                <i className="fab fa-github" />
              </MKTypography>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Information />
        <Team />
        <Featuring />
        <Newsletter />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  )
}
export default AboutUs
