// @mui icons
import GitHubIcon from '@mui/icons-material/GitHub'
import MKTypography from 'components/MKTypography' // Images
import logo from 'assets/images/logo-datai.gif'

const date = new Date().getFullYear()
export default {
  brand: {
    name: 'Data-i Consulting',
    image: logo,
    route: '/',
  },
  socials: [
    // {
    //   icon: <FacebookIcon />,
    //   link: '',
    // },
    // {
    //   icon: <TwitterIcon />,
    //   link: '',
    // },
    {
      icon: <GitHubIcon />,
      link: 'https://github.com/data-i-consulting',
    },
    // {
    //   icon: <YouTubeIcon />,
    //   link: '',
    // },
  ],
  menus: [
    {
      name: 'company',
      items: [
        // { name: 'about us', href: '/' },
        // { name: 'freebies', href: 'https://www.creative-tim.com/templates/free' },
        // { name: 'premium tools', href: 'https://www.creative-tim.com/templates/premium' },
        // { name: 'blog', href: 'https://www.creative-tim.com/blog' },
      ],
    },
    {
      name: 'resources',
      items: [
        // { name: 'illustrations', href: 'https://iradesign.io/' },
        // { name: 'bits & snippets', href: 'https://www.creative-tim.com/bits' },
        // { name: 'affiliate program', href: 'https://www.creative-tim.com/affiliates/new' },
      ],
    },
    {
      name: 'help & support',
      items: [
        // { name: 'contact us', href: 'https://www.creative-tim.com/contact-us' },
        // { name: 'knowledge center', href: 'https://www.creative-tim.com/knowledge-center' },
        // { name: 'custom development', href: 'https://services.creative-tim.com/' },
        // { name: 'sponsorships', href: 'https://www.creative-tim.com/sponsorships' },
      ],
    },
    {
      name: 'legal',
      items: [
        // { name: 'terms & conditions', href: 'https://www.creative-tim.com/terms' },
        // { name: 'privacy policy', href: 'https://www.creative-tim.com/privacy' },
        // { name: 'licenses (EULA)', href: 'https://www.creative-tim.com/license' },
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy;
      {' '}
      {date}
      {' '}
      Data-i Consulting Co., Ltd.
    </MKTypography>
  ),
}
