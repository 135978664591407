export default {
  defaultProps: {
    disableGutters: true,
  },
  styleOverrides: {
    root: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
}
