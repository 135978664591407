import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid' //  React components
import MKBox from 'components/MKBox'
import MKTypography from 'components/MKTypography' //  React examples
import HorizontalTeamCard from 'examples/Cards/TeamCards/HorizontalTeamCard' // Images
import team1 from 'assets/images/team-5.jpg'

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              The Executive Team
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              Meat our team of experts who are ready to assist you on your new venture.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image={team1}
                name="Zoltan Varadi"
                position={{ color: 'info', label: 'CEO' }}
                description="Data scientist by heart, developer by profession"
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  )
}
export default Team
